import React from 'react';

import YouTubeVideo from '../components/YouTubeVideo';

export default () => {
  return (
    <div className="row">
      <YouTubeVideo ytId="gLLl3VbNFXg" volumn={25} />
    </div>
  );
};
