const script = {
  1: 'Full-Duplex Scalable Client-Server Communication with WebSockets and Spring Boot',
  2: 'Instant feedback is the key to effective communication',
  3: 'Keep the audience from falling asleep',
  4: "Since the WebApp is hosted on ordenary web server, which is much more reliable, and clients are likely to load the content at different time slot, that means one server'll be enough to support 1,000 participant.",
  5: 'Reducing kilobytes of data to 2 bytes is more than "a little more byte efficient"',
  6: 'Reducing latency from 150ms(TCP round trip to set up the connection plus a packet for the message) to 50ms(just the packet for the message) is far more than marginal.',
  7: 'WebSocket is a bidirectional, full-duplex, frame-base protocol.',
  8: 'HTML5 Web Sockets  especially for real-time, event-driven web applications.',
  9: '1.Establish a socket connection between the client and the server using HTTP for the initial handshake.',
  10: '2.Switch the communication protocol from HTTP to a socket-based protocol.',
  11: '3.Send Messages in both directions simultaneously (full-duplex mode).',
  12: '4.Both the server and the client can initiate the data transmission ==> enable the real server-side push.',
  13: '5.Disconnect by both sides.',
  15: '7 URL schemes: [ws] & [wss]-Encrypted',
  // 15: 'HTML5 Web Sockets can provide a 500:1 or—depending on the size of the HTTP headers—even a 1000:1 reduction in unnecessary HTTP header traffic and 3:1 reduction in latency. ',
  16: 'As a Synchronous Information system, It can support up to 1M users for some on-going events (Television, Concerts, Movice, Press Conference, Tour Guide Service, Sport Events/Olympics. )',
  17: 'As a Remote Control (Public LED, Smart TV, Vending Machine, Food Ordering ) - use your mobile as the touch screen',
  18: 'PPT-like shareing, Online Education, Online Bidding/Selling, On-site Promotion, A theme party(Annual party)',
  // 19: 'The most common protocol of internet',
  // 20: "Http is equipped with lot of headers to actually reach it's destination over the internet.",
  21: 'WebSocket is a simple TCP based protocol which does the initial handshake by http and keeps a persistent tcp connection to the server..',
  22: 'Except the typical initial handshake which will be in http, the rest of communication is headerless and lightweight.',
  23: 'Unlike HTTP, it is a duplex connection that allows client and server push frames from either side. Hence allowing the client and server to communicate in more real-time.',
  24: 'A full-duplex communication channel that operates.',
  25: 'Difficult to Scale Up (2, 10, 100 ...). Besides network, need servers to process the video converting ',
  26: 'Difficult to Scale Out (more functionalities) ',
  27: 'User Experience -- (poor video quality;  network breakdown) ',
  28: 'Poor Outcome (Not much to share before and after the meeting, Instead of sharing the PPT/Recorded-video after the meeting, this content persistent, feedbacks are available)',
  29: 'Lack of interactions (demo...)',
  30: 'Specific softwares are needed (Skype, MS Teams)',
  31: 'Cost - The more the attendees, the more it cost, the poorer the service',
  32: 'Hard to check involvement (What are the students doing)',
  33: 'Video, Audio, Image, Web Link, Chart, etc. Reuseable content(i.e. The code shared here can be easily copied.)',
  34: 'Voting, Bidding, Games,...',
  35: "It'll be a Before, During and After the 'Meeting' Seminar. ",
  99: ''
};

export default script;
